.cardForm {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-stack-xs);
  padding: var(--spacing-squish-lg);
}

.subtitle {
  color: var(--color-neutral-dark-1);
  font-size: var(--font-size-lg);
}

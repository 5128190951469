.subtitle {
  color: var(--color-neutral-dark-4);
  font-size: var(--font-size-lg);
}

.iconInfo {
  cursor: pointer;
  color: var(--color-neutral-dark-4);
}

.titles {
  color: var(--color-neutral-dark-3);
}

.visibilityToggle {
  cursor: pointer;
}

.textDisabled {
  color: var(--color-neutral-light-1);
}

.ball {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--color-brand-primary-base);
}

.activeLabel {
  color: var(--color-brand-primary-base);
}

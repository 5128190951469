.shellInformation {
  padding: var(--spacing-stack-2xl) !important;
  padding-bottom: var(--spacing-stack-xl) !important;
}

.emphasisText {
  font-weight: bold;
  color: var(--color-brand-primary-base);
  cursor: pointer;
}

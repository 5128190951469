.headerCardExpansion > span {
  margin-right: var(--spacing-inline-xs);
  color: var(--color-brand-secondary-dark);
}

.deleteButton {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: -100%;
  cursor: pointer;
}

.deleteButton > span {
  margin-bottom: -5px;
}

.noDevicesText {
  height: 300px;
}

.deviceData {
  font-size: var(--font-size-xs);
}

.errorIcon {
  color: var(--color-status-critical-base);
}

.warningIcon {
  color: var(--color-status-alert-base);
}

.dividerCard {
  height: 1px;
  border: none;
  background-color: var(--color-neutral-light-3);
}

.errorText {
  color: var(--color-status-critical-base);
  font-size: var(--font-size-sm);
  line-height: 21px;
}

.warningText {
  color: var(--color-status-alert-darkest);
  font-size: var(--font-size-sm);
  line-height: 21px;
}

.loadingModal {
  display: flex;
  flex-direction: column;
  height: calc(70vh - 250px);
  overflow: auto;
}

.contentModalDevicesWithErrors {
  display: flex;
  flex-direction: column;
  height: calc(70vh - 150px);
  overflow: auto;
}

.iconHelpDrawer {
  display: inline;
  vertical-align: middle;
}

.linkToHelpDrawer {
  color: inherit;
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: underline;
}

.buttons {
  justify-content: center;
  margin-left: 16px;
  width: 33%;
}

.politicsText {
  color: var(--color-status-ok-base);
  cursor: pointer;
  background-color: var(--card-bg);
  border: none;
}

.labelWithError {
  color: var(--color-status-critical-base);
}

.labelWarning {
  font-size: var(--font-size-sm);
  color: var(--color-neutral-dark-5);
}

.formInfosCaptive {
  display: flex;
  flex-direction: column;
  width: 60%;
  min-width: 450px;
}

.formPreview {
  display: flex;
  justify-content: center;
  width: fit-content;
  min-width: 380px;
}

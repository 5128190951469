.menuActions {
  gap: var(--spacing-inset-2xs);
  cursor: pointer;
  z-index: 1;
}

.colorExitSite {
  color: var(--color-status-critical-base);
}

.colorMenu {
  color: var(--color-status-ok-base);
}

.colorIconMenu {
  color: var(--color-brand-primary-base);
}

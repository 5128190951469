.shellTitle {
  margin-top: 49px;
}

.titles {
  color: var(--color-neutral-dark-3);
}

.iconInfo {
  cursor: pointer;
  color: var(--color-neutral-dark-4);
}

.backToSitesButton {
  border: none;
  cursor: pointer;
  background: none;
  align-items: center;
  gap: 16px;
}

.iconBack {
  width: 24px;
  height: 24px;
  color: var(--color-neutral-light-5);
  background: var(--color-brand-primary-dark);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backButtonWithText {
  width: fit-content;
}

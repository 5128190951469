.backToSitesButton {
    border: none;
    width: 24px;
    height: 24px;
    color: var(--color-neutral-light-5);
    background: var(--color-brand-primary-dark);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
.stepsShell {
  display: flex;
  justify-content: center;
  width: 100%;
}

.contentSteps {
  width: 300px;
}

.stepItem {
  word-break: normal;
}

.cardOfMethods {
  padding: var(--spacing-squish-lg);
}

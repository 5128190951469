.duoToneIcons {
  margin-right: var(--spacing-stack-2xl);
}

.cardSite {
  width: 100%;
  padding: 0;
  color: var(--color-neutral-dark-3);
  gap: 96px;
}

.rightCardBody {
  height: 112px;
}

.modalPosition {
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalContainer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  background: rgba(26, 26, 26, 0.5);
}

.overlayModalOpen {
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--spacing-stratch-xs) var(--spacing-stratch-sm)
    var(--spacing-stratch-xxs) var(--spacing-stratch-sm);
}

.scrollableModal {
  overflow-y: auto;
}

.headerModal {
  display: flex;
  align-items: center;
}

.titleModal {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-bold);
  font-style: normal;
  font-size: var(--font-size-xl);
  line-height: var(--font-size-3xl);
  color: var(--color-neutral-dark-1);
}

.titleModalWithIcon {
  margin-right: var(--spacing-inline-3xs);
  width: 50%;
}

.titleModalWithOutIcon {
  width: 100%;
}

.contentModal {
  margin-top: var(--spacing-stack-sm);
}

.lastActionButton {
  margin-left: var(--spacing-inset-xs);
}

.closeButton {
  justify-content: flex-end;
}

.actionButtonEven:nth-child(even) {
  margin-left: var(--spacing-inset-xs);
}

.actionsButton {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--spacing-inline-xs);
}

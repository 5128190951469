.modalContainer {
  max-height: 80vh;
  overflow: auto;
  margin-bottom: 20px;
}

.statusContent {
  width: 100px;
}

.createdAtContent {
  color: var(--color-neutral-dark-4);
}

.cardBody {
  max-width: 510px;
  box-shadow: var(--shadow-level-3) rgba(0, 0, 0, 0.15);
}

.cardContentWithImage {
  line-height: 0;
}

.cardBodyWithImage {
  border-radius: 0 0 var(--border-radius-sm) var(--border-radius-sm);
  line-height: initial;
  border-top: none !important;
}

.emphasisText {
  font-weight: bold;
  color: var(--color-brand-primary-base);
  cursor: pointer;
}

.logo {
  border-radius: var(--border-radius-sm) var(--border-radius-sm) 0 0;
  height: auto;
  max-height: 150px;
  width: 100%;
  max-width: 510px;
  box-shadow: var(--shadow-level-3) rgba(0, 0, 0, 0.1);
  border-bottom: none;
}

.defaultMethod {
  color: var(--color-brand-primary-base);
  cursor: pointer;
  background-color: var(--card-bg);
  border: none;
}

.isReadOnly {
  pointer-events: none;
}

.inputwrapper {
  position: relative;
  width: 100%;
}

.inputwrapper label {
  display: flex;
}

.inputiconinside {
  position: relative;
  width: 100%;
}

.inputiconinsideWithLeftIcon {
  padding-left: 48px;
}

.inputiconinside .icon_inside_left {
  font-size: 24px;
  position: absolute;
  top: 13px;
  left: var(--spacing-inset-md);
}

.inputiconinside .icon_inside_right {
  font-size: 24px;
  position: absolute;
  top: 13px;
  right: var(--spacing-inset-md);
  color: var(--input-icon-action-color);
}

.valid label,
.valid > div {
  color: var(--color-status-ok-dark);
}

.valid input,
.valid > div > div {
  border-color: var(--color-status-ok-dark);
  outline-color: var(--color-status-ok-dark);
}

.invalid label,
.invalid > div {
  color: var(--color-status-critical-base);
}

.invalid input,
.invalid > div > div {
  border-color: var(--color-status-critical-base) !important;
  outline-color: var(--color-status-critical-base);
}

.alert > div > span:first-child {
  color: var(--color-status-alert-base);
}

.btnRedirect {
  color: var(--color-brand-primary-base);
  cursor: pointer;
}

.btnRedirectInWizard {
  color: var(--color-product-base);
  cursor: default;
}

.previewShell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  min-width: 380px;
}

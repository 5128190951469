/* Estrutura dos tokens: [tipo]-[breakpoint]-[tamanho]
* tipo: title/text
* breakpoint: xl/sm
* tamanho: xl/lg/base/sm
*/

/* breakpoint xl */
.text-xl-lg {
  font-family: var(--font-family-secondary);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-lg);
}

// Text with 16px font-size
.text-xl-base {
  font-family: var(--font-family-secondary);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-base);
}

.text-xl-sm {
  font-family: var(--font-family-secondary);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-sm);
}

.text-xl-xs {
  font-family: var(--font-family-secondary);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-xs);
}

/* breakpoint sm */
.text-sm-lg {
  font-family: var(--font-family-secondary);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-base);
}

.text-sm-base {
  font-family: var(--font-family-secondary);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-sm);
}

.text-sm-sm {
  font-family: var(--font-family-secondary);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-sm);
}

.text-sm-xs {
  font-family: var(--font-family-secondary);
  font-style: normal;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-xs);
}

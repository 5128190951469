.title {
  color: var(--color-neutral-dark-3);
}

.shellSites {
  padding: var(--spacing-stack-2xl) !important;
  padding-bottom: var(--spacing-stack-xl) !important;
}

.cardOfSitesAndSharedSites {
  min-height: calc(100vh - 300px);
}

.textDisabled {
  color: #e2e2e2;
}

.skeletonSites {
  margin-bottom: var(--spacing-inset-xl);
}

.rightCardBody {
  height: 112px;
}
